<template>
  <v-dialog v-model="show" max-width="620" :retain-focus="false">
    <v-card>
      <!-- Header -->
      <v-card-title>
        <v-row no-gutters align="center" justify="center">
          <v-col cols="1">
            <v-btn icon plain @click.stop="show = false">
              <v-icon>{{ icons.mdiClose }}</v-icon>
            </v-btn>
          </v-col>
          <v-col class="d-flex justify-center">
            <h4>Set scoring</h4>
          </v-col>
          <v-col cols="1" />
        </v-row>
      </v-card-title>

      <!-- Tabs -->
      <v-card-text>
        <v-tabs v-model="tab" show-arrows>
          <v-tab v-for="item in tabItems" :key="item.title">
            {{ item.title }}
          </v-tab>
        </v-tabs>
      </v-card-text>

      <!-- Content -->
      <v-card-text>
        <v-tabs-items v-model="tab">
          <!-- Frist Tab Content -->
          <v-tab-item>
            <v-list dense>
              <v-list-item v-for="(scoreParam, i) in Object.keys(scoringWhatsApp)" :key="scoreParam + i">
                <v-list-item-avatar min-width="100">
                  <span class="text-capitalize">{{ scoreParam }}</span>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>
                    <v-text-field v-model="scoringWhatsApp[scoreParam]" outlined dense hide-details></v-text-field>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-tab-item>

          <!-- Second Tab Content -->
          <v-tab-item>
            <v-list dense>
              <v-list-item v-for="(scoreParam, i) in Object.keys(scoringFacebook)" :key="scoreParam + i">
                <v-list-item-avatar min-width="100">
                  <span class="text-capitalize">{{ scoreParam }}</span>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>
                    <v-text-field v-model="scoringFacebook[scoreParam]" outlined dense hide-details></v-text-field>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-tab-item>

          <!-- Third Tab Content -->
          <v-tab-item>
            <v-list dense>
              <v-list-item v-for="(scoreParam, i) in Object.keys(scoringInstagram)" :key="scoreParam + i">
                <v-list-item-avatar min-width="100">
                  <span class="text-capitalize">{{ scoreParam }}</span>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>
                    <v-text-field v-model="scoringInstagram[scoreParam]" outlined dense hide-details></v-text-field>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>

      <!-- Footer -->
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="#F15858" outlined class="text-capitalize" :disabled="saving" @click="setToDefault(tab)">
          Set to default
        </v-btn>

        <v-btn color="#F15858" class="text-capitalize text-white" :disabled="saving" @click="save"> Save </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiClose } from '@mdi/js'

export default {
  setup() {
    return {
      icons: { mdiClose },
    }
  },
  props: {
    value: { type: Boolean, required: true },
  },
  emits: [],
  data() {
    return {
      tab: null,
      tabItems: [{ title: 'WhatsApp' }, { title: 'Facebook' }, { title: 'Instagram' }],

      // scoringWhatsApp: {},
      // scoringFacebook: {},
      // scoringInstagram: {},
      saving: false,
    }
  },
  computed: {
    // To avoid changing props directly, dialog's v-model will use this
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    user() {
      return this.$store.getters['auth/getUser']
    },
    scoringWhatsApp() {
      return this.$store.getters['campaign_manager/getScoringData'].whatsapp
    },
    scoringFacebook() {
      return this.$store.getters['campaign_manager/getScoringData'].facebook
    },
    scoringInstagram() {
      return this.$store.getters['campaign_manager/getScoringData'].instagram
    },
  },
  methods: {
    setToDefault(channel) {
      switch (channel) {
        case 0:
          this.scoringWhatsApp.read = 5
          this.scoringWhatsApp.replied = 5
          this.scoringWhatsApp.click = 5
          break
        case 1:
          this.scoringFacebook.view = 5
          this.scoringFacebook.click = 5
          this.scoringFacebook.comment = 5
          this.scoringFacebook.like = 5
          this.scoringFacebook.saved = 5
          this.scoringFacebook.share = 5
          break
        case 2:
          this.scoringInstagram.view = 5
          this.scoringInstagram.click = 5
          this.scoringInstagram.comment = 5
          this.scoringInstagram.like = 5
          this.scoringInstagram.saved = 5
          this.scoringInstagram.share = 5
          break
        default:
          break
      }
    },
    async save() {
      this.saving = true
      try {
        await this.$store.dispatch('campaign_manager/updateScoring', {
          sub_id: this.user.sub_id,
          data: {
            whatsapp: this.scoringWhatsApp,
            facebook: this.scoringFacebook,
            instagram: this.scoringInstagram,
          },
          token: this.user.token,
        })
        this.saving = false
        this.show = false
      } catch (error) {
        this.saving = false
        console.log(error)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.v-tab {
  text-transform: none !important;
}
</style>
